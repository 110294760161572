import "hc-offcanvas-nav/dist/hc-offcanvas-nav";
/*••••••••••••••••••••••••••••••••••••••••••••••
         Mobile pen close Menu
         ••••••••••••••••••••••••••••••••••••••••••••••*/
let Nav = $("#mobile-nav").hcOffcanvasNav({
  maxWidth: 980,
  position: "left",
  pushContent: true,
});

$("#menu-mobile-open").on("click", function (event) {
  event.preventDefault();
  Nav.open();
});
