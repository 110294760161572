import "owl.carousel";

import "./cookie-consent";
import "./navigation.mobile";
import "./shared/mailchimp.newsletter";

/**
 * Image separator parallax
 */
if ($(".image-separator").length > 0) {
  // TODO: do something ?
}

/**
 * Send Newsletter
 */
$(".section-header-newsletter-input").on("keypress", function (e) {
  if (e.which === 13) {
    e.preventDefault();
    sendHeaderNewsletter($(event.target));
  }
});

$(".section-header-newsletter-send").on("click", function (event) {
  event.preventDefault();
  sendHeaderNewsletter(
    $(
      $(event.target)
        .parent()
        .parent()
        .find(".section-header-newsletter-input")
        .get(0),
    ),
  );
});

let sendHeaderNewsletter = function (emailInput) {
  // eslint-disable-next-line no-undef
  sendNewsletterWithMailChimp(
    emailInput.data("url"),
    emailInput,
    emailInput.data("list_id"),
    $(".section-header-newsletter"),
    $(".section-header-newsletter-loading"),
    $(".section-header-newsletter-success"),
    $(".section-header-newsletter-error"),
  );
};

$(".keep-in-touch-input").on("keypress", function (e) {
  if (e.which === 13) {
    e.preventDefault();
    sendFooterNewsletter($(event.target));
  }
});

$(".keep-in-touch-send").on("click", function (event) {
  event.preventDefault();
  sendFooterNewsletter($("#newsletter_email"));
});

let sendFooterNewsletter = function (emailInput) {
  // eslint-disable-next-line no-undef
  sendNewsletterWithMailChimp(
    $("#keep-in-touch").data("action"),
    emailInput,
    emailInput.data("id"),
    $(".keep-in-touch-newsletter"),
    $(".keep-in-touch-loading"),
    $(".keep-in-touch-success"),
    $(".keep-in-touch-error"),
  );
};

if ($(".parallax").length > 0) {
  // eslint-disable-next-line no-unused-vars
  $(window).on("scroll", function (evt) {
    let $parallax = $(".parallax");
    let scrollPosition = $(window).scrollTop();
    let top = $parallax.position().top;

    $parallax.css({
      "background-position": "50% " + (scrollPosition - top) * 0.2 + "px",
    });
  });
}

/*••••••••••••••••••••••••••••••••••••••••••••••
 Widget Content LightGallery and Video Player
 ••••••••••••••••••••••••••••••••••••••••••••••*/
if ($("widget-gallery").length > 0) {
  // eslint-disable-next-line no-undef
  lightGallery(document.getElementById("widget-gallery"));
}
if ($("widget-video").length > 0) {
  // eslint-disable-next-line no-undef
  lightGallery(document.getElementById("widget-video"), { selector: "this" });
}

var owl2 = $("#recent_posts").owlCarousel({
  loop: true,
  items: 3,
  margin: 30,
  stagePadding: 0,
  autoplay: false,
  nav: false,
  dots: false,
  responsive: {
    0: {
      margin: 30,
      items: 1,
      autoplay: true,
    },
    575: {
      margin: 30,
      items: 2,
      autoplay: true,
    },
    767: {
      items: 3,
    },
  },
});

$("#carousel2-prev").click(function () {
  owl2.trigger("prev.owl.carousel");
});
$("#carousel2-next").click(function () {
  owl2.trigger("next.owl.carousel");
});
