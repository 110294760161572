window.sendNewsletterWithMailChimp = function (
  url,
  emailElement,
  id,
  newsletterElement,
  loadingElement,
  successElement,
  errorElement,
) {
  newsletterElement.hide();
  loadingElement.show();

  $.ajax({
    type: "POST",
    url: url,
    data: JSON.stringify({ email: emailElement.val(), id: id }),
    contentType: "application/json; charset=utf-8",
    dataType: "json",
    // eslint-disable-next-line no-unused-vars
    success: function (data, status) {
      loadingElement.hide();
      successElement.show();

      setTimeout(function () {
        loadingElement.hide();
        successElement.hide();
        errorElement.hide();
        emailElement.val("");
        newsletterElement.show();
      }, 3000);
    },
    // eslint-disable-next-line no-unused-vars
    error: function (errMsg) {
      loadingElement.hide();
      successElement.hide();
      errorElement.show();
      emailElement.val("");
      newsletterElement.hide();

      setTimeout(function () {
        loadingElement.hide();
        successElement.hide();
        errorElement.hide();
        emailElement.val("");
        newsletterElement.show();
      }, 3000);
    },
  });
};
