/*•••••••••••••••••••••••••••••••••••••••••••••••••••••••••
Consent Banner
•••••••••••••••••••••••••••••••••••••••••••••••••••••••••*/
var applyConsent = function (all) {
  all = typeof all !== "undefined" ? all : null;
  var youtube =
    all === null ? $('input[name="consent-youtube"]').is(":checked") : all;
  var ga = all === null ? $('input[name="consent-ga"]').is(":checked") : all;
  var consents = { ga: ga, youtube: youtube };

  var d = new Date();
  d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie =
    "CONSENT" + "=" + JSON.stringify(consents) + ";" + expires + ";path=/";
  window.location.reload();
};

let banner = $("#consent-banner");

if (banner.hasClass("active")) {
  banner.addClass("added");
  banner.toggleClass("d-none");
}

$("#consent-customize").click(function () {
  $("#consent-items").show();
  $(".consent-btn").hide();
  $(".consent-apply").show();
  $(".consent-close").show();
});

$(".consent-close").click(function () {
  $("#consent-items").hide();
  $(".consent-btn").show();
  $(".consent-apply").hide();
  $(".consent-close").hide();

  if (!banner.hasClass("active")) {
    banner.toggleClass("d-none");
  }
});

$(".consent-apply").click(function () {
  applyConsent();
});

$("#consent-allow").click(function () {
  applyConsent(true);
});

$("#consent-deny").click(function () {
  applyConsent(false);
});

$(".cookie-settings").click(function (event) {
  event.preventDefault();
  $("#consent-banner").toggleClass("d-none");
  $("#consent-items").show();
  $(".consent-btn").hide();
  $(".consent-apply").show();
  $(".consent-close").show();
});

$(".open-cookie-settings").click(function (event) {
  event.stopPropagation();
  banner.removeClass("d-none");
  banner.addClass("added");
});
